<template>
  <div class="menu-manage clearfix">
    <div class="menu-tree pull-left box-shadow"  v-loading="treeLoading">
      <div class="menu-title">目录树</div>
      <el-tree
      :data="menuList"
      node-key="id"
      default-expand-all
      :expand-on-click-node="false">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span   @click="clickItem(data)">{{ data.name }}</span>
        <span class="operation">
          <el-button
            style="margin-left:15px"
            type="text"
            size="mini"
            @click="() => append(node, data)">
            新建
          </el-button>
          <el-button
            style="margin-left:15px"
            v-if="!data.children"
            type="text"
            size="mini"
            @click="() => remove(node, data)">
            删除
          </el-button>
        </span>
      </span>
    </el-tree>
    </div>
    <div class="menu-item  pull-left box-shadow">
      <div class="menu-title">目录详情</div>
      <el-form ref="menuItem" :model="menuItem"  label-width="100px" :rules="rules">
        <el-form-item label="目录名称"  prop="name">
          <el-input v-model="menuItem.name"></el-input>
        </el-form-item>
        <el-form-item label="链接地址">
          <el-input v-model="menuItem.href"></el-input>
        </el-form-item>
        <!-- <el-form-item label="图标字体">
          <el-input v-model="menuItem.icon"></el-input>
        </el-form-item> -->
        <el-form-item label="权限名称">
          <el-input v-model="menuItem.permission"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number size="medium" v-model="menuItem.sort"></el-input-number>
        </el-form-item>
        <el-form-item label="是否显示">
          <el-radio v-model="menuItem.isShow" label="1">是</el-radio>
          <el-radio v-model="menuItem.isShow" label="0">否</el-radio>
        </el-form-item>
        <el-form-item label="打开方式">
          <el-radio v-model="menuItem.target" label="_blank">新窗口</el-radio>
          <el-radio v-model="menuItem.target" label="_self">本窗口</el-radio>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="menuItem.remarks"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align:center">
        <button class="btn-search" @click="submit()">提交</button>
      </div>
    </div>
  </div>
</template>
<script>
import { AdminGetDirectoryTree, AdminDirectoryTreeItem, AdminDirectoryTreeDel, AdminDirectoryTreeUpdate, AdminDirectoryTreeAdd } from '@/api/adminApi'
export default {
  name: 'SettingMenu',
  data() {
    return {
      pattern: '', // edit add
      treeLoading: false,
      rules: {
        name: [{ required: true, message: '请输入目录名称', trigger: 'blur' }]
        // href: [{ required: true, message: '请输入目录名称', trigger: 'blur' }]
      },
      menuList: [],
      menuItem: {
        id: '',
        href: '',
        icon: '',
        permission: '',
        isService: '0',
        remarks: '',
        isShow: '1',
        name: '',
        parentId: '',
        sort: '',
        target: '',
        type: ''
      }
    }
  },
  created() {
    this.getAllMenu()
  },
  methods: {
    getAllMenu() {
      this.treeLoading = true
      AdminGetDirectoryTree({}).then(resp => {
        this.treeLoading = false
        if (resp.code === '000') {
          this.menuList = [...resp.result]
        }
      })
    },
    append(node, data) {
      const newChild = { name: '新增节点', parentId: data.id, type: data.type, children: [] }
      if (!data.children) {
        this.$set(data, 'children', [])
      }
      data.children.push(newChild)
      this.pattern = 'add'
      this.clearItemData(data.id, data.type)
    },
    remove(node, data) {
      this.$confirm('此操作将删除该目录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res => {
        AdminDirectoryTreeDel({ id: data.id }).then(resp => {
          if (resp.code === '000') {
            this.$message({ message: '删除成功', type: 'success' })
            this.getAllMenu()
          } else {
            this.$message({ message: '操作失败', type: 'error' })
          }
        })
      })
    },
    clickItem(data) {
      if (!data.id) {
        this.pattern = 'add'
        this.clearItemData(data.parentId, data.type)
      } else {
        this.pattern = 'edit'
        AdminDirectoryTreeItem({ id: data.id }).then(resp => {
          if (resp.code === '000') {
            this.menuItem.id = resp.result.id
            this.menuItem.href = resp.result.href
            this.menuItem.icon = resp.result.icon
            this.menuItem.isService = resp.result.isService
            this.menuItem.remarks = resp.result.remarks
            this.menuItem.isShow = resp.result.isShow
            this.menuItem.name = resp.result.name
            this.menuItem.parentId = resp.result.parentId
            this.menuItem.sort = resp.result.sort
            this.menuItem.target = resp.result.target
            this.menuItem.type = resp.result.type
            this.menuItem.permission = resp.result.permission
          }
        })
      }
    },
    submit() {
      if (this.pattern) {
        this.$refs.menuItem.validate(valid => {
          if (valid) {
            if (this.pattern === 'edit') {
              console.log(JSON.stringify(this.menuItem))
              AdminDirectoryTreeUpdate(this.menuItem).then(resp => {
                if (resp.code === '000') {
                  // this.$toast('msg', '操作成功')
                  this.$message({ message: '操作成功', type: 'success' })
                  this.menuItem = {
                    id: '',
                    href: '',
                    icon: '',
                    permission: '',
                    isService: '0',
                    remarks: '',
                    isShow: '1',
                    name: '',
                    parentId: '',
                    sort: '',
                    target: '',
                    type: ''
                  }
                  this.getAllMenu()
                }
              })
            } else if (this.pattern === 'add') {
              delete this.menuItem.id
              AdminDirectoryTreeAdd(this.menuItem).then(resp => {
                if (resp.code === '000') {
                  // this.$toast('msg', '操作成功')
                  this.$message({ message: '操作成功', type: 'success' })
                  this.menuItem = {
                    id: '',
                    href: '',
                    icon: '',
                    permission: '',
                    isService: '0',
                    remarks: '',
                    isShow: '1',
                    name: '',
                    parentId: '',
                    sort: '',
                    target: '',
                    type: ''
                  }
                  this.getAllMenu()
                }
              })
            }
          } else {
            return false
          }
        })
      } else {
        // this.$toast('msg', '请选择对应的目录')
        this.$message({ message: '请选择对应的目录', type: 'error' })
      }
    },
    clearItemData(pid, type) {
      this.menuItem.id = ''
      this.menuItem.parentId = pid
      this.menuItem.name = '新增节点'
      this.menuItem.href = ''
      this.menuItem.icon = ''
      this.menuItem.isService = '0'
      this.menuItem.remarks = ''
      this.menuItem.isShow = '1'
      this.menuItem.sort = ''
      this.menuItem.target = ''
      this.menuItem.type = type
    }
  }
}
</script>
<style lang="less" scoped>
.menu-manage {
  width: 100%;
  height: 100%;
  padding: 20px 50px 30px 50px;
  .menu-tree {
    width: 40%;
    height: 100%;
    overflow: auto;
    padding: 20px;
    border-right: 1px solid #ddd;
  }
  .menu-item {
    width: 58%;
    margin-left: 2%;
    height: 100%;
    overflow: auto;
    padding: 20px;
  }
}
.menu-title {
  text-align: left;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 20px;
}
.operation {
  display: none;
}
.el-tree-node__content:hover .operation {
  display: inline-block;
}
</style>
